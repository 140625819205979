'use client';

import type { ReactNode } from 'react';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

// import Button from '@/components/atoms/Button'
import Title from '@/components/atoms/Title';
const CustomizeErrorBoundary = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    t
  } = useTranslation();
  const {
    reset
  } = useQueryErrorResetBoundary();
  return <ErrorBoundary onReset={reset}
  // onError={(error) => {
  //   console.error(error)
  // }}
  fallbackRender={({}) => <div className="py-20 flex items-center justify-center flex-col">
          <Title>{t('error.There are currently no data')}</Title>
          {/* <Button type="button" className="mt-10" onClick={() => resetErrorBoundary()}>
            {t('common.Try again')}
           </Button> */}
        </div>}>
      {children}
    </ErrorBoundary>;
};
export default CustomizeErrorBoundary;